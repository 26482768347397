/* Mobile styling */
video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: -1;
	opacity: 0.8;
	rotate: 1.5deg;
}

.hero-container {
	height: calc(100vh - 5rem);
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
	object-fit: contain;
	/* background-image: url('../../public/images/hero-poster.png'); */
}

.hero-container h1 {
	color: #fff;
	font-size: 2.5rem;
	padding: 0rem 1.5rem;
	text-align: center;
}

.hero-container p {
	color: #fff;
	font-size: 1.5rem;
	padding: 0 1.5rem;
	text-align: center;
}

.hero-btns {
	padding-top: 2rem;
}



/* Desktop styling */
@media only screen and (min-width: 650px) {
	.hero-container h1 {
		font-size: 6rem;
		padding: 0 0 0.25rem 0;
	}

	.hero-container p {
		font-size: 2rem;
	}
	.hero-btns {
		padding: 3rem 0 0 0;
	}
}
