/* Mobile styling */
body {
  background-color: #000;
}

.projectpage__video {
  opacity: 1;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 84vw;
  margin: 0px auto 5rem;
  color: #fff;
  /* background-color: #000; */
}

.cards__container h2 {
  text-align: center;
  padding: 2.5rem 0;
  font-size: 2.5rem;
}

.cards__container h3 {
  text-align: center;
  padding: 2rem 0 3rem 0;
  font-size: 1.5rem;
}

.cards__wrapper {
  position: relative;
}

.cards__items {
  display: block;
  list-style: none;
}

.cards__item {
  margin-bottom: 2rem;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 93vw;
  /* box-shadow: 0 0px 6px rgba(255, 255, 255, 0.3); */
  /* -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
  /* filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
  border-radius: 1rem;
  overflow: hidden;
  text-decoration: none;
  background-color: #1d1d1f;
  /* border: 1px solid rgb(48, 48, 48); */
}

/* .cards__item__link:hover {
  box-shadow: 0 0 0.75rem 0 rgba(255, 255, 255, 0.6);
} */

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.cards__item__pic-wrap::after {
  display: none;
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 1rem;
  padding: 0.375rem 0.5rem;
  max-width: calc((100%) - 60px);
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
  background-color: #7369ff;
  box-sizing: border-box;
  border-radius: 0.375rem 0.375rem 0 0;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.05s linear;
}

.cards__item__img:hover {
  transform: scale(1.02);
}

.cards__item__info {
  padding: 1rem 1.5rem 1.5rem;
  /* background-color: rgba(255, 255, 255, 0.05); */
}

.cards__item__text {
  color: #fff;
  font-size: 1rem;
  line-height: 1.5rem;
}

.project-contact {
  width: 100%;
  text-align: center;
  /* margin: 5rem 0 0; */
  padding: 3rem 1rem;
  background-color: #1d1d1d;
  border-radius: 1rem;
  background-image: url('/public/images/project-contact.jpeg');
  background-size: cover;
  background-position: center;
}

.project-value {
  padding: 0 0 2rem 0;
  display: flex;
  flex-direction: column;
}
.project-value h3 {
  padding: 0rem 0rem 0.5rem 0rem;
}

.hide {
  display: none;
}

/* Desktop styling */
@media only screen and (min-width: 650px) {
  .cards__container {
    width: 90vw;
  }
  
  .cards__wrapper {
    margin: 0px 0px 50px 0px;
  }

  .cards__items {
    margin-bottom: 2rem;
    display: flex;
    /* justify-content: space-around ; */
  }
  .two__in__one {
    width: 48vw;
  }

  .cards__item {
    display: flex;
    flex: 1;
    margin: 0 0.5rem 1rem 0.5rem;
  }

  .project-contact {
    margin: 0 auto;
    width: calc((100%) - 1rem);
  }
  
}

  @media only screen and (min-width: 1080px) {
    .cards__container {
      width: 70vw;
    }

    /* .cards__item__link:hover {
      box-shadow: 0 0 0.75rem 0 rgba(255, 255, 255, 0.3);
    } */
    
    .two__in__one {
      width: 40vw;
    }

    .project-value {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .project-value h3{
      padding-right: 0.5rem;
    }
  }
