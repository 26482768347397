/* Mobile styling */
.contact {
	height: 90vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    color: #ffffff;
}

.contact video {
    opacity: 0.6;
}

.contact h1 {
    font-size: 40px;
    text-align: center;
}

.contact p {
    font-size: 20px;
    text-align: center;
}

.contact i {
    font-size: 40px;
}

.social__links {
    margin: 20px;
}

.social__links a {
    text-decoration: none;
    margin: 5px;
    color:#ffffff;
}

/* Desktop styling */
@media only screen and (min-width: 650px) {
    .contact h1 {
        font-size: 80px;
    }

    .contact p {
        font-size: 40px;
    }

    .contact i {
        font-size: 60px;
    }
}