.scroll-to-top-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  background-color: #000000;
  border: none;
  border-radius: 50%;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-to-top-button.show {
  opacity: 1;
}
