:root {
	--primary: #fff;
}

.btn {
	padding: 8px 20px;
	border-radius: 0.5rem;
	outline: none;
	border: none;
	cursor: pointer;
}

.btn--primary {
	background-color: var(--primary);
	color: #000;
	/* border: 1px solid var(--primary); */
}

.btn--outline {
	background-color: transparent;
	color: #fff;
	padding: 8px 20px;
	border: 1px solid var(--primary);
	transition: all 0.3s easy-out;
}

.btn--medium {
	padding: 8px 20px;
	font-size: 16px;
}

.btn--large {
	padding: 12px 26px;
	font-size: 20px;
}

.btn--medium:hover,
.btn--large:hover {
	background: #e9e9e9;
	color: #000;
	transition: all 0.3s easy-out;
}
