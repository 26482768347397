* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  /* color: #fff; */
}

img {
  width: 100%;
}

a:visited {
  /* text-decoration: none; */
  color: #ffffff;
}

/* project styling */
.black__container {
  background-color: #000000;
  color: #ffffff;
  padding-bottom: 80px;
}

.white__container {
  background-color: #ffffff;
  color: #000000;
  padding-bottom: 80px;
  /* display: flex;
  flex-direction: column; */
}

.project__container div {
  display: flex;
	flex-direction: column;
	align-items: left;
  font-size: 0;
}

.white__container h2 {
  color: #000000;
}

.prototype__link {
  padding: 50px;
  color: #000000;
  font-size: 20px;
  text-align: center;
}

/* .prototype__link a {
  color: #fff;
  font-size: 20px;
} */

.project__video {
  position: absolute; 
  /* right: 0; 
  bottom: 0; */
  /* min-width: 100%; 
  min-height: 100%; */
  width: auto; 
  height: 80vh; 
  z-index: -100;
  background-size: cover;
  overflow: hidden;
  opacity: 1;
}

.form {
  background-color: #ffffff15;
  border: 1px solid #393939;
  border-radius: 0.5rem;
  box-shadow: 0 2px 6px 2px #ffffff25;
  width: auto;
  margin: 5rem 1rem;
  padding: 2rem;
  color: #ffffff;
  text-align: center;
}

.form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem auto;
}

.form input {
  width: 100%;
  padding: 0.8rem 1rem;
  margin: 2rem;
  border: 1px solid #ffffff;
  border-radius: 0.25rem;
  background-color: #00000000;
  color: #ffffff;
}
@media only screen and (min-width: 720px) {
  /* .project__container {
    padding: 0 10vw 20vh 10vw;
  } */

  .form {
    width: 40vw;
    margin: 5rem auto;
    padding: 2rem 4rem;
  }
}