/* Mobile styling */
.navbar {
  background: rgba(0, 0, 0, 1);
  height: 5rem;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1.25rem;
  position: sticky;
  top: 0;
  z-index: 999;
  color: #ffffff;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 5rem;
  width: 80vw;
}

.navbar-logo {
  margin-left: 8vw;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding: 1rem 0 1rem 0;
  top: 0;
}

.navbar-logo img {
  height: auto;
  width: 2.5rem;
  border-radius: 0.25rem;
}

.menu-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 3vw;
  transform: translate(-100%, 60%);
  font-size: 1.5rem;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  position: absolute;
  top: 5rem;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu.active {
  background: rgba(0, 0, 0, 1);
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}

.nav-links {
  text-align: center;
  text-decoration: none;
  padding: 2rem;
  width: 100%;
  display: table;
}

.nav-links:hover {
  /* background-color: rgb(255, 255, 255); */
  color: #e9e9e9;
  border-radius: 0;
}

.fa-times {
  color: #fff;
  font-size: 2rem;
}

.desktop-menu {
  display: none;
}



/* Desktop styling */
@media only screen and (min-width: 650px) {
    .navbar {
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(30px);
    }

    .navbar-container {
      margin: 0 auto;
    }

    .desktop-menu {
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 1rem;
      list-style: none;
      text-align: center;
      width: 60vw;
      justify-content: end;
      opacity: 1;
    }

    .nav-menu, .nav-menu.active {
      display: none;
    }

    .navbar-logo {
      margin-left: 0.5rem;
    }

    .nav-item {
      height: 5rem;
    }

    .nav-links {
      color: #fff;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.5rem 1rem;
      height: 100%;
    }

    .nav-links:hover {
      color: #e9e9e9;
      /* color: #fff;
      background-color: #000; */
      /* border-bottom: 3px solid #fff; */
      transition: all 0.2s ease-out;
    }

    .fa-bars {
      color: #fff;
    }

    .menu-icon {
      display: none;
    }
  }